@use '~@moda/om';

.NotFoundPage {
  margin: om.spacing(8, 0);

  &__head {
    margin: om.spacing(0, 0, 8);
  }

  &__title {
    @include om.text(h5, $font: serif);

    color: om.color(ink);
    text-align: center;
  }

  &__description {
    @include om.text(body1);

    max-width: 42rem;
    margin: 0 auto;
    color: om.color(ink);
    text-align: center;
  }

  @include om.breakpoint(xs) {
    margin: om.spacing(9, 0);

    &__head {
      margin: om.spacing(0, 0, 9);
    }

    &__title {
      @include om.text(h3, $font: serif);
    }

    &__description {
      @include om.text(h6);
    }
  }
}
