@use '~@moda/om';

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.PlaceholderModule {
  &__content {
    position: absolute;
    inset: om.space(4);
    background-color: om.color(noise);
    animation: pulse 2s infinite;
  }
}
